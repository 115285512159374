import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Layout from '../components/layout/Layout';

const Contact = () => {

  return (
    <Layout>
      <section id="contact" className="py-20 lg:pb-20 lg:pt-28">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-4xl font-semibold">Page not found.</h2>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
